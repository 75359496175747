import { Box, CircularProgress, Tooltip, styled } from "@mui/material";
import React from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ListTemplate from "../ListTemplate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SendIcon from "@mui/icons-material/Send";
import AudioRecorderSection from "../components/AudioRecorderSection";
// import { AudioRecorder } from "react-audio-voice-recorder";

const TypeBox = styled(Box)({
   height: "100",
});



function ChatInput({
   openTemplate,
   setOpenTemplate,
   selectedChat,
   setMessageList,
   isChatExpired,
   handleClickFileUpload,
   fileuploadRef,
   handleFileChange,
   textMessage,
   handleChange,
   started,
   setStarted,
   handleSendMessage,
   sendMessageLoading,
}) {
   return (
      <TypeBox sx={{ width: "100%" }}>
         <Box
            sx={{
               display: "flex",
               alignItems: "center",
               border: "1px solid #80808026",
               backgroundColor: "#f0f2f5",
            }}
         >
            <Box
               gap={2}
               pl={2}
               sx={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
               }}
            >
               {openTemplate && (
                  <ListTemplate
                     openTemplateList={openTemplate}
                     setOpenTemplateList={setOpenTemplate}
                     setMessageList={setMessageList}
                     selectedChat={selectedChat}
                  />
               )}
               {!isChatExpired && (
                  <>
                     <AddCircleOutlineIcon
                        sx={{ color: "#999", cursor: "pointer" }}
                        onClick={handleClickFileUpload}
                     />
                     <input
                        style={{ display: "none" }}
                        type="file"
                        // accept="image/,video/"
                        ref={fileuploadRef}
                        onChange={handleFileChange}
                     />
                  </>
               )}
               <Tooltip title="Templates">
                  <FontAwesomeIcon
                     onClick={() => setOpenTemplate(!openTemplate)}
                     style={{
                        color: "#999",
                        cursor: "pointer",
                        "&:hover": { color: "black" },
                     }}
                     icon="fa-solid fa-layer-group"
                  />
               </Tooltip>
               {/* {selectedChat?._id !== "new" && <FontAwesomeIcon style={{ color: "#999" }} icon="fa-solid fa-share-from-square" />} */}
            </Box>
            {!started && (
               <input
                  placeholder={
                     selectedChat?._id === "new" || isChatExpired
                        ? "You can only send templates to this number"
                        : "Type a Message"
                  }
                  disabled={selectedChat?._id === "new" || isChatExpired}
                  value={textMessage}
                  onChange={handleChange}
                  // onKeyDown={handleKeyDown}
                  style={{
                     width: "100%",
                     height: "40px",
                     outline: "none",
                     border: "none",
                     paddingLeft: "25px",
                     backgroundColor: "#f0f2f5",
                  }}
               />
            )}
            {!textMessage && !isChatExpired ? (
               <Box
                  sx={{
                     backgroundColor: "#80808024",
                     // width: "50px",
                     height: "44px",
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "center",
                     cursor: "pointer",
                  }}
               >
                  {/* <AudioRecorder started={started} setStarted={setStarted} /> */}
               </Box>
            ) : (
               <Box
                  sx={{
                     backgroundColor: "#80808024",
                     width: "50px",
                     height: "44px",
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "center",
                     cursor: "pointer",
                  }}
               >
                  {textMessage && sendMessageLoading ? (
                     <CircularProgress
                        sx={{ width: "20px", height: "20px", color: "#000000" }}
                     />
                  ) : (
                     textMessage && (
                        <SendIcon onClick={() => handleSendMessage()} />
                     )
                  )}
               </Box>
            )}
            {/* <Box
          sx={{
            backgroundColor: "#80808024",
            width: "50px",
            height: "44px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          {textMessage && sendMessageLoading ? (
            <CircularProgress sx={{ width: "20px", height: "20px", color: "#000000" }} />
          ) : (
            textMessage && <SendIcon onClick={() => handleSendMessage()} />
          )}
        </Box> */}

            {!textMessage && !isChatExpired && <AudioRecorderSection started={started} setStarted={setStarted} selectedChat={selectedChat}/>}
         </Box>
      </TypeBox>
   );
}

export default ChatInput;
